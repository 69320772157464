import React, { createContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { decryptData, encryptData } from '../utils/encrypting';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const router = useRouter();

    const [cart, setCart] = useState([]);
    const [bands, setBands] = useState([]);
    const [popUp, setPopUp] = useState(false);

    // Fetch bands from API and store them in localStorage after encrypting
    useEffect(() => {
        const fetchBands = async () => {
            try {
                const query = await fetch('https://tickets.adventureresortkufri.com/api/v1/getplans?token=adventure');
                const res = await query.json();
                
                // Encrypt data before storing in localStorage
                localStorage.setItem('bands', encryptData(res.tickets));
                setBands(res.tickets);
            } catch (error) {
                console.error("Error fetching bands:", error);
            }
        };

        fetchBands();
    }, []);

    // Load the cart from localStorage and decrypt it, if available
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const storedCart = localStorage.getItem('cart');
            if (storedCart) {
                try {
                    setCart(decryptData(storedCart)); // Decrypt the cart
                } catch (error) {
                    console.error("Error decrypting cart:", error);
                }
            }
        }
    }, []);

    // Load the bands from localStorage if they exist, and decrypt them
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const storedBands = localStorage.getItem('bands');
            if (storedBands) {
                try {
                    const decryptedBands = decryptData(storedBands);
                    setBands(decryptedBands);
                } catch (error) {
                    console.error("Error decrypting bands:", error);
                }
            }
        }
    }, []);

    // Update localStorage whenever the cart is updated
    useEffect(() => {
        if (cart.length > 0) {
            try {
                localStorage.setItem('cart', encryptData(cart)); // Encrypt the cart
            } catch (error) {
                console.error("Error encrypting cart:", error);
            }
        }
    }, [cart]);

    const removeFromCart = (itemId) => {
        setCart((prevItems) =>
            prevItems.filter((item) => item.id !== itemId)
        );
    };

    const handleAddToCart = (band, quantity) => {
        const bandToAdd = { ...band, quantity };
        const isItemInCart = cart.some((cartItem) => cartItem.id === band.id);

        if (isItemInCart) {
            const updatedCart = cart.map((cartItem) => {
                if (cartItem.id === band.id) {
                    return { ...cartItem, quantity };
                }
                return cartItem;
            });
            setCart(updatedCart);
        } else {
            setCart((prevCart) => [...prevCart, bandToAdd]);
        }
        setPopUp(true);
    };

    const handleBookNow = (band, quantity) => {
        handleAddToCart(band, quantity); // Add item to cart
        router.push('/tickets/cart/checkout', undefined, { shallow: true }); // Navigate to cart page
    };

    const handleIncrementQuantity = (bandId) => {
        setBands((prevBands) => {
            const updatedBands = prevBands.map((band) => {
                if (band.id === bandId) {
                    const newQuantity = parseInt(band.quantity) + 1;
                    updateCartItemQuantity(bandId, newQuantity);
                    return { ...band, quantity: newQuantity };
                }
                return band;
            });

            // Encrypt and update bands in localStorage
            localStorage.setItem('bands', encryptData(updatedBands));
            setBands(updatedBands);
            return updatedBands;
        });
    };

    const handleDecrementQuantity = (bandId) => {
        setBands((prevBands) => {
            const updatedBands = prevBands.map((band) => {
                if (band.id === bandId && band.quantity > 1) {
                    const newQuantity = band.quantity - 1;
                    updateCartItemQuantity(bandId, newQuantity);
                    return { ...band, quantity: newQuantity };
                }
                return band;
            });

            // Encrypt and update bands in localStorage
            localStorage.setItem('bands', encryptData(updatedBands));
            setBands(updatedBands);

            return updatedBands;
        });
    };

    const updateCartItemQuantity = (bandId, newQuantity) => {
        setCart((prevCart) => {
            return prevCart.map((item) => {
                if (item.id === bandId) {
                    return { ...item, quantity: newQuantity };
                }
                return item;
            });
        });
    };

    const cartItem = cart.length;

    return (
        <CartContext.Provider value={{
            cart,
            cartItem,
            bands,
            popUp,
            setPopUp,
            setBands,
            setCart,
            handleAddToCart,
            removeFromCart,
            handleBookNow,
            updateCartItemQuantity,
            handleIncrementQuantity,
            handleDecrementQuantity
        }}>
            {children}
        </CartContext.Provider>
    );
};
